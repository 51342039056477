.front {
  h1.shop-heading {
    text-align: center;
    font-family: $bazar;
    font-size: 50px;
    text-transform: uppercase;
  }
  h2.shop-heading {
    text-align: center;
    font-family: $bazar;
    font-size: 20px;
    text-transform: uppercase;
  }
  .shops-index {
    margin-top: 20px;
  }
  .shop-window {
    margin: 20px 0;
    .shop-window-body {
    }
    .shop-window-footer {
      position: relative;
      z-index: 1;
      padding: 20px 30px;
      background-color: $red;
      color: #fff;
      text-align: center;
      background-image: url(../../repo/img/shop-window-footer-bg.jpg);
      background-repeat: repeat;
      .shop-window-name {
        a {
          color: #fff;
          font-family: $bazar;
          font-size: 26px;
        }
      }
      .shop-window-city {
        font-family: $raleway;
        font-size: 13px;
      }
      .shop-window-stars {
        margin: 5px;
      }
      .shop-window-tagline {
        font-family: $raleway;
        font-size: 14px;
      }
      .shop-window-left-border {
        position: absolute;
        z-index: 2;
        left: 10px;
        bottom: 10px;
        width: 48px;
        height: 46px;
        background-image: url(../../repo/img/shop-window-left-border.png);
        background-repeat: no-repeat;
      }
      .shop-window-right-border {
        position: absolute;
        z-index: 2;
        right: 10px;
        bottom: 10px;
        width: 46px;
        height: 48px;
        background-image: url(../../repo/img/shop-window-right-border.png);
        background-repeat: no-repeat;
      }
    }
  }
  .shop-page {
    .shop-page-body {
      margin: 40px 0;
      padding: 0 60px;
      font-size: 15px;
      max-height: 300px;
      overflow-y: auto;
    }
    .shop-page-footer {
      margin: 40px 0;
      padding: 40px;
      font-size: 15px;
      background-color: #e7e7e7;
      p {
        strong {
          text-decoration: underline;
        }
      }
    }
    .shop-page-detail-heading {
      font-weight: 700;
      border-bottom: 1px solid #333;
      margin: 0 0 5px;
    }
    .shop-page-detail-body {
      margin: 0 0 20px;
    }
    .shop-page-featured-items {
      height: 500px;
      position: relative;
      z-index: 1;
      .product-shelf-bg-shell {
        position: absolute;
        left: 50%;
        z-index: 1;
        .product-shelf-bg {
          position: relative;
          left: -50%;
          z-index: 2;
        }
      }

      //background-image: url(../../repo/img/product-shelf-bg.jpg);
      //background-repeat:no-repeat;
      //background-size:cover;
      //background-position:center;
      .featured-product-window {
        padding: 20px 0 100px;
        img {
          position: absolute;
          z-index: 2;
        }
      }
    }
    .product-window-footer-shop {
      display: none;
    }
  }
}

@media (max-width: 600px) {

}
@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
@font-face {
    font-family: 'bazar';
    src: url('../fonts/custom/bazar/bazar.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import 'blue/variables/bootstrap';
@import '../../../bower_components/fontawesome/scss/font-awesome';
@import '../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap';
@import 'shared/colorbox';
@import 'ignore/videojs';
@import 'ignore/swiper';

@import 'blue/variables/local';
@import 'blue/global';

@import 'blue/layout/header';
@import 'blue/layout/footer';
@import 'blue/layout/breadcrumbs';
@import 'blue/layout/swiper';

@import 'blue/content/checkout';
@import 'blue/content/map';
@import 'blue/content/one-liners';
@import 'blue/content/portals';
@import 'blue/content/products';
@import 'blue/content/search';
@import 'blue/content/shops';
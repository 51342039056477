.front {
  .map-shell {
    display: none;
    background-image: url(../../repo/img/4-red-stripes-bg.png);
    background-repeat: repeat-x;
    background-position: center;
    .container {
      position: relative;
      z-index: 1;
      .map-header {
        margin: 20px 0;
      }
      .map-body {
        padding: 0 0 30px;
        text-align:center;
        iframe {
        }
      }
      .map-footer {
        margin: 20px 0;
        position: absolute;
        right:0;
        bottom:0;
        z-index: 999;
      }
      .map-footer-left {
        margin: 20px 0;
        position: absolute;
        left:0;
        bottom:0;
        z-index: 999;
      }
    }
  }
  &.browse {
    .map-shell {
      display: block;
    }
  }
}

@media (max-width: 600px) {
  .front {
    .map-shell {
      background-image: none;
      .container {
        .map-body {
          display: none;
        }
        .map-footer {
          position: static;
          //float: right;
          //display: inline-block;
          //margin: 0 auto;
        }
        .map-footer-left {
          position: static;
          //float: left;
          //display: inline-block;
          //margin: 0 auto;
        }
      }
    }
  }
}
.front {
  .btn.btn-cart {
    background-color: $tiel;
    font-size: 18px;
    color: #fff;
  }
  #products-index {
    margin-top: 20px;
  }
  h1.product-heading {
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
  }
  .product-page-misc-heading {
    margin: 10px 0;
    font-family: $bazar;
    font-size: 25px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
  }
  .form-add-to-cart {
    font-size: 14px;
  }
  .product-window {
    margin: 20px 0;
    img {

    }
    .product-window-body {
      border: 1px solid $gray;
      border-bottom: 0;
    }
    .product-window-footer {
      padding: 20px 30px;
      background-color: $red;
      color: #fff;
      background-image: url(../../repo/img/shop-window-footer-bg.jpg);
      background-repeat: repeat;
      .product-window-footer-name {
        a {
          color: #fff;
          font-size: 17px;
          text-decoration: underline;
        }
      }
      .product-window-footer-intro {
        font-size: 13px;
      }
      .product-window-footer-shop {
        div {
          @media (min-width: $screen-sm-min) {
            position: relative;
            top: 25px;
          }
        }
        a {
          font-size: 13px;
          color: #fff;
          text-decoration: underline;
        }
      }
      .product-window-footer-price {
        font-size: 24px;
        font-family: Diodrum, Arial, sans-serif;
        margin: 5px 0;
        text-align: right;
        .msrp {
          font-size: 15px;
          color: $gray;
          span {
            text-decoration: line-through;
          }
        }
      }
      .product-window-footer-btn {
        text-align: right;
      }
    }
  }
  .product-offer {
    font-size: 50px;
    font-family: Arial, sans-serif;
    font-weight: 700;
    margin: 5px 0;
    text-align: right;
    .msrp {
      font-size: 30px;
      color: $red;
      span {
        text-decoration: line-through;
      }
    }
    .btn.btn-cart {
      display: inline-block;
      font-size: 35px;
    }
    i {
      font-size: 28px;
    }
    form {
      display: inline-block;
      max-width: 300px;
    }
  }
  .product-page {
    .product-page-header {
      margin: 20px 0;
    }
    .product-page-body {
      padding: 20px;
      font-size: 16px;
    }
    .related-products {
      .product-window-footer-shop {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {

}
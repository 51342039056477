.front {
  .breadcrumbs {
    background-color: $gray;
    .breadcrumb {
      background-color: $gray;
      margin: 10px 0;
      li {
        font-size: 16px !important;
      }
      a {
        font-size: 16px !important;
      }
    }
    .breadcrumb.breadcrumb-locations,
    .breadcrumb.breadcrumb-categories {
      margin-bottom: 0;
      & > li + li:before {
        content: ">";
      }
    }
    .breadcrumb-locations-with-category {
      li.breadcrumb-locations-first::before {
        content: ' | ' !important;
      }
    }
  }
}
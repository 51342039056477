.front {
  footer {
    background-color: $tiel;
    .footer-top {
      padding: 30px 0 25px;
      color: #fff;
      background-color: $gold;
      font-size: 45px;
      font-family: $bazar;
      a.btn {
        font-family: $raleway;
        font-size: 20px;
        padding:20px 75px;
        background-color: $red;
      }
    }
    .footer-main {
      padding: 0;
      background: $tiel url(../../repo/img/footer-bg.jpg) repeat;
      hr {
        margin:0;
        padding:0;
        height: 3px;
        color: $gold;
        background: $gold;
        font-size: 0;
        border: 0;
      }
      h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        height: 35px;
        margin-bottom: 20px;
        &::after {
          content:"";
          background: $blue;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          width: 45px;
        }
      }
      ul {
        list-style:none;
        margin:0 0 20px 0;
        padding: 0;
        li {
          color: #fff;
          font-size: 14px;
          line-height: 1.5em;
          a {
            color: #fff;
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
      a.logo img {
        margin-top: 93px;
      }
    }
    .footer-bottom {
      background-color: $dark_gray;
      color: #fff;
      min-height: 50px;
      padding: 20px 0 15px;
      p {
        font-size: 12px;
      }
      ul {
        li {
          display: inline;
          a {
            color: $gold;
            font-weight: 400;
            font-size: 12px;
          }
          &.first::after {
            content: "|";
            color: $gold;
            padding: 0 5px 0 7px;
          }
        }
      }
    }
  }

  #footer-modal-trigger {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    a.btn {
      color: #000;
      text-shadow: 0 1px 0 #fff;
      background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
      background-image: -o-linear-gradient(top, #fff 0, #e0e0e0 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e0e0e0));
      background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      background-repeat: repeat-x;
      border-color: #ccc;
      padding: 3px 5px;
      border-radius: 0;
    }
  }

  #footer-modal {
    .container {
      width: 100%;
    }
  }

}
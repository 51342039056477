$blue: #146995;
$dark_gray: #424244;
$gold: #fabf37;
$gray: #f3f3f3;
$red: #ae1a36;
$tiel: #53a7ad;

$bazar: 'bazar', Georgia, sans-serif;
$raleway: 'Raleway', Arial, sans-serif;

@mixin border-radius($radius: 1px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
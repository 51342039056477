.front {
  .search-shell {
    padding: 30px 0 25px;
    color: #fff;
    background-color: $blue;
    .container {
      .search-body {
        #search-modes-dropdown-menu {
          float: right !important;
          button {
            height: 34px;
            background-color: $gray;
            border:1px solid #ccc;
            border-right: 0;
            min-width: 160px;
          }
          .dropdown-menu {
            top: 31px;
            color: #000;
            background-color: $gray;
          }
        }
        @media (max-width: $screen-sm-max) {
          #search-modes-dropdown-menu {
            float: none !important;
            width: 100%;
            button {
              width: 100%;
            }
            .dropdown-menu {
            }
          }
        }
        input.search-input {
          @include border-radius(0);
          width: 100% !important;
        }
        button {
          @include border-radius(0);
          background-color: $red;
          width: 100%;
        }
        .form-group {
          margin: 0 !important;
        }
        .search-modes-dropdown-menu {
          color: #000;
        }
      }
      .search-footer {
      }
    }
  }
  &.browse {
    .search-shell {
      background-color: $gold;
    }
  }
}
body.front {
  background-color: $dark_gray;
  font-family: $raleway;
  p {
    font-size: 15px;
  }
  #main {
    background-color: #ffffff;
    padding-bottom: 20px;
  }
  .gold-stripe {
    height: 15px;
    background-image: url(../../repo/img/gold-stripe.png);
    background-repeat: repeat-x;
    background-position: center;
  }
  .alert {
    margin: 20px 0;
  }
  .row.no-gutter {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no-gutter [class*='col-']:not(:first-child),
  .row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }
  .diagonal-bg {
    background-image: url(../../repo/img/diagonal-bg.jpg);
    background-repeat: repeat;
  }
  .btn.scroll-next {
    background-color: $blue;
    font-family: $bazar;
    font-size: 20px;
    border: 0;
    text-transform: uppercase;
    color: #fff;
  }
  .red-banner-heading {
    background-color: $red;
    color: #fff;
    font-family: $bazar;
    font-size: 44px;
    text-align: center;
    padding: 20px 0;
  }
  .site-alert-banner {
    background-color: $tiel;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
  }
  .shopper-trust {
    margin: 20px;
    .shopper-trust-shopper-shield {
      a {
        display: block;
        font-size: 22px;
        text-align: center;
        margin: 10px 0;
      }
    }
    div {
      margin:10px 0;
    }
  }
  .small-label {
    display: block;
    font-size: 13px;
  }
  .lead {
    font-size: 21px !important;
  }
  img.img-responsive {
    //width: 100%;
  }
}
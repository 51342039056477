.front {
  .form-cc-shell {
    label {
      margin: 0 0 5px;
      display: block;
    }
    a i {
      color: #000;
    }
    .small-label {
      margin: 5px 0;
    }
    .panel-heading {
      p {
        margin: 5px 0
      }
    }
  }
}
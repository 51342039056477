.front {
  .header-shell {
    background-color: $tiel;
    background-image: url(../../repo/img/header-bg.jpg);
    background-repeat: repeat;
    background-position: center;
  }
  header {
    padding: 10px 0 0 0;
    .header-metrics {
      text-align: center;
      ul {
        margin: 10px 0;
        padding: 0;
        list-style-type: none;
        li {
          display: inline-block;
          padding:0;
          margin: 0 20px 0 0;
        }
      }
    }
    .header-logo {
      img {
        margin-left: 25px;
        @media (max-width: $screen-md-max) {
          margin-left: 10px;
        }
      }
    }
    .header-as-seen-on {
      text-align: center;
      p {
        color: #fff;
        font-size: 14px;
        margin: 5px 0;
      }
      img {
        display: block;
        max-height: 70px;
        margin: 0 auto;
      }
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        text-align: right;
        p {
          margin: 5px;
        }
        img {
          display: inline-block;
          margin:0 10px;
        }
      }
    }
    nav {
      border: 0;
      border-radius: 0;
      background-color: $red;
      margin: 15px 0 0 0;
      @media (max-width: $screen-sm-max) {
        margin: 0;
      }
      ul {
        background-color: $red !important;
      }
      a {
        background-color: $red !important;
        color: #fff !important;
        font-size: 14px !important;
        font-family: $raleway !important;
        &.active {
          color: $gold !important;
          font-weight: 700;
          text-decoration: underline;
        }
        &:hover {
          color:#fff;
          text-decoration: underline;
        }
      }

      &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 25px 25px 25px 25px;
        border-color: $red transparent;
        left: -25px;
      }

      .icon-bar {
        background-color: #fff !important;
      }

      .dropdown-menu {
        border:0 !important;
      }
    }
  }
}
.front {
  .portals-index {
    margin-top: 20px;
  }
  .portal-page {
    .portal-page-heading {
      background-color: $dark_gray;
      color: #fff;
      font-family: $bazar;
      text-align: center;
      padding: 20px 0;
      .portal-page-heading-name {
        font-size: 44px;
        span {
          padding:10px 20px;
          margin:0 10px;
          border:2px solid #fff;
        }
      }
    }
    .portal-page-body {
      margin: 40px 0;
      padding: 0 60px;
      font-size: 15px;
      max-height: 300px;
      overflow-y: auto;
      iframe {
        margin:0 20px;
      }
    }
  }
  .portal-window {
    margin: 20px 0;
    .portal-window-footer {
      position: relative;
      z-index: 1;
      padding: 20px 0;
      background-color: $dark_gray;
      color: $gray;
      text-align: center;
      border:1px solid darken($gray, 10%);
      border-top: 0;
      .portal-window-star {
        font-size: 30px;
      }
      .portal-window-name {
        a {
          display: inline-block;
          padding:10px 20px;
          border:2px solid #fff;
          color: #fff;
          font-family: $bazar;
          font-size: 26px;
        }
      }
      .portal-window-tagline {
        font-family: $raleway;
        font-size: 14px;
      }
    }
  }
}
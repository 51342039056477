.front {
  .one-liners {
    margin: 20px 0;
    text-align: center;
    blockquote {
      border: 0;
      margin: 0 auto;
      position: relative;
      text-align: center;
      display: table;
      font-size: 50px;
      @media (max-width: $screen-sm-max) {
        font-size: 30px;
      }
      font-family: $bazar;
      text-transform: uppercase;
    }
    blockquote:before, blockquote:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      height: 7px;
      margin-left: -40%;
      background: $red;
    }
    blockquote:after {
      top: inherit;
      bottom: 0;
    }
  }
}